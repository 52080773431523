import React from "react"
import { navigate, Link } from "gatsby"
import ReactPlayer from "react-player"
import amplitude from "../../utils/amplitude"
import ProBanner from "./proBanner"
import classes from "./landing.module.css"
import tabHellImg from "../../images/tab-hell.png"
import chromeLogo from "../../images/chrome_logo.png"

class Landing extends React.Component {
  componentDidMount() {
    amplitude.logEvent("landing page view")
  }

  renderQuickSellButtons() {
    return (
      <>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.GATSBY_CHROME_WEB_STORE_LISTING_LINK}
          className={classes.seamlessButton}
          onClick={() => {
            amplitude.logEvent("click add_free from quick_sell")
          }}
        >
          Add to Chrome for free
        </a>
        <div className={classes.orText}>or</div>
        <Link
          to="/#pro"
          className={classes.seamlessButton}
          onClick={() => {
            amplitude.logEvent("click get_pro from quick_sell")
          }}
        >
          Get Pro
        </Link>
      </>
    )
  }

  render() {
    return (
      <div>
        <div className={classes.tabHellImg} src={tabHellImg}>
          <div className={classes.quickSellDiv}>
            <div className={classes.quickSellTitle}>
              Solve the Chrome tabs mess
            </div>
            <div className={classes.quickSellItem}>
              Frustrated with the amount of tabs you have open?
            </div>
            <div className={classes.quickSellItem}>
              Safely close the tabs that are not needed now
            </div>
            <div className={classes.quickSellItem}>
              Restore from the cloud later
            </div>
            <div className={classes.quickSellItem}>
              Browse like normal, <br /> everything is saved automatically
            </div>
            <div className={classes.quickSellButtons}>
              {this.renderQuickSellButtons()}
            </div>
          </div>
        </div>

        <div className={classes.quickSellButtonsBottom}>
          {this.renderQuickSellButtons()}
        </div>

        <div style={{ width: "fit-content", margin: "0 auto" }}>
          <img style={{ height: 90 }} src={chromeLogo} />
        </div>

        <div className={classes.howItWorksContainer}>
          <div className={classes.howItWorksTitle}>How it works</div>
          <div className={classes.howItWorksContent}>
            <div className={classes.howItWorksContentInner}>
              <div className={classes.playerWrapper}>
                <ReactPlayer
                  className={classes.reactPlayer}
                  url="https://youtu.be/c0L7kPtu0jA"
                  volume={0}
                  muted
                  playing={true}
                  width="100%"
                  height="100%"
                />
              </div>
              <div style={{ paddingLeft: 20 }}>
                <div className={classes.howItWorksItem}>
                  <div className={classes.howItWorksNumber}>1</div>
                  <div className={classes.howItWorksDescription}>
                    <div className={classes.howItWorksHeader}>
                      Install the browser extension for free
                    </div>
                  </div>
                </div>
                <div className={classes.howItWorksItem}>
                  <div className={classes.howItWorksNumber}>2</div>
                  <div className={classes.howItWorksDescription}>
                    <div className={classes.howItWorksHeader}>
                      Browse like normal
                    </div>
                    <div className={classes.howItWorksSubHeader}>
                      Seamless automatically backs up your sessions
                    </div>
                  </div>
                </div>
                <div className={classes.howItWorksItem}>
                  <div className={classes.howItWorksNumber}>3</div>
                  <div className={classes.howItWorksDescription}>
                    <div className={classes.howItWorksHeader}>
                      Reduce clutter
                    </div>
                    <div className={classes.howItWorksSubHeader}>
                      Too many tabs? Close your current session and pick up
                      where you left off later.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.freeContainer}>
          <div className={classes.freeInnerContainer}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.GATSBY_CHROME_WEB_STORE_LISTING_LINK}
              className={classes.getFreeButton}
              onClick={() => {
                amplitude.logEvent("click add_free from banner")
              }}
            >
              Add to Chrome for free
            </a>
            <div style={{ paddingTop: 15 }} />
            <div className={classes.freeText}>
              Reduce clutter & increase performance
            </div>
            <div className={classes.freeText}>Everything is in the cloud</div>
            <div className={classes.freeText}>
              No manual work required - your tabs are backed up automatically
            </div>
            <div className={classes.freeText}>
              Transition between devices - just restore a session from the cloud
            </div>
          </div>
        </div>

        <div id="pro" className={classes.proContainer}>
          <div
            style={{
              fontSize: 28,
              width: "fit-content",
              margin: "0 auto 20px auto",
            }}
          >
            Pro version
          </div>
          <ProBanner
            onClick={() => {
              amplitude.logEvent("click get_pro from banner")
              navigate("/app/account")
            }}
          />
        </div>
      </div>
    )
  }
}

Landing.propTypes = {}

Landing.defaultProps = {}

export default Landing
