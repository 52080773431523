import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Landing from "../components/ui/landing"
import { isLoggedIn } from "../utils/auth"

const IndexPage = () => {
  if (isLoggedIn()) {
    navigate("/app")
    return null
  }

  return (
    <Layout>
      <SEO
        title="Seamless Browser - Home"
        description="Back up Chrome tabs with this extension"
      />
      <Landing />
    </Layout>
  )
}

export default IndexPage
